import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import mailTemplateApi from "../api/mailTemplateApi";
import {
  MailTemplate,
  MailTemplateInfo,
  MailTemplateInsertRequest,
  MailTemplateUpdateRequest,
} from "../../types/Info";
import {
  ParsedEMLReqDto,
  Scenario,
  ScenarioListReqDto,
} from "../../types/Scenario";

interface MailTemplateState {
  mailTemplateList: Scenario[];
  total: number;
  mailTemplateInfo?: MailTemplateInfo;
  mailTemplateDetail?: Scenario;
}

// # initial state
const initialState: MailTemplateState = {
  mailTemplateList: [],
  total: 0,
  mailTemplateInfo: undefined,
  mailTemplateDetail: undefined,
};
export const trunkGetMailTemplateList = createAsyncThunk(
  "mailTemplate/list",
  async (req: ScenarioListReqDto) => {
    const resp = await mailTemplateApi.getMailTemplateList(req);
    return resp;
  }
);
export const trunkInsertMailTemplateList = createAsyncThunk(
  "mailTemplate/insert",
  async (req: { request: MailTemplateInsertRequest; file: File }) => {
    const resp = await mailTemplateApi.insertMailTemplate(req);
    return resp;
  }
);
export const trunkUpdateMailTemplateList = createAsyncThunk(
  "mailTemplate/update",
  async (req: { request: MailTemplateUpdateRequest; file?: File }) => {
    const resp = await mailTemplateApi.updateMailTemplate(req);
    return resp;
  }
);
export const trunkGetMailTemplateInfo = createAsyncThunk(
  "mailTemplate/info",
  async () => {
    const resp = await mailTemplateApi.getMailTemplateInfo();
    return resp;
  }
);
export const trunkGetUsedTemplate = createAsyncThunk(
  "mailTemplate/getUsedTemplate",
  async () => {
    const resp = await mailTemplateApi.getUsedTemplate();
    return resp;
  }
);
export const trunkParseEMLFile = createAsyncThunk(
  "mailTemplate/parseEML",
  async (req: { file: any }) => {
    const resp = await mailTemplateApi.parseEMLFile(req);
    return resp;
  }
);
export const trunkGetMailTemplateData = createAsyncThunk(
  "mailTemplate/getMailTemplateData",
  async (index: number) => {
    const resp = await mailTemplateApi.getMailTemplateData(index);
    return resp;
  }
);
export const trunkDeleteMailTemplate = createAsyncThunk(
  "mailTemplate/delete",
  async (req: {
    request: {
      templateIds: number[];
    };
  }) => {
    const resp = await mailTemplateApi.deleteMailTemplate(req);
    return resp;
  }
);
// export const trunkSetUpdateTemplateId = createAsyncThunk(
//   "mailTemplate/setId",
//   async (templateId: number) => {
//     return templateId;
//   }
// );
const mailTemplateSlice = createSlice({
  name: "mailTemplate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      trunkGetMailTemplateList.fulfilled,
      (state, { payload: data }) => {
        if (data.data) {
          state.mailTemplateList = data.data.list;
          state.total = data.data.total;
        }
      }
    );
    builder.addCase(
      trunkInsertMailTemplateList.fulfilled,
      (state, { payload: data }) => {
        if (data.code === "SUCCESS") {
          state.mailTemplateList = [...state.mailTemplateList, data.data];
          state.total = state.total + 1;
        }
      }
    );
    builder.addCase(
      trunkGetMailTemplateInfo.fulfilled,
      (state, { payload: data }) => {
        state.mailTemplateInfo = data;
      }
    );
    builder.addCase(
      trunkGetMailTemplateData.fulfilled,
      (state, { payload: data }) => {
        state.mailTemplateDetail = data.data;
      }
    );

    builder.addCase(trunkDeleteMailTemplate.fulfilled, (state, req) => {
      if (req.payload.code === "SUCCESS") {
        state.mailTemplateList = state.mailTemplateList.filter(
          (item) =>
            !req.meta.arg.request.templateIds.some(
              (id) => item.templateId === id
            )
        );
        state.total = state.total - 1;
      }
    });

    builder.addCase(
      trunkUpdateMailTemplateList.fulfilled,
      (state, { payload: data }) => {
        if (data.code === "SUCCESS") {
          state.mailTemplateList = state.mailTemplateList.map((item) =>
            item.templateId === data.data.templateId
              ? { ...item, ...data.data }
              : item
          );
        }
      }
    );

    // builder.addCase(trunkParseEMLFile.fulfilled, (state, { payload: data }) => {
    //   if (data.code === "SUCCESS") {
    //     state.parsedEmlInfo = data.data;
    //   }
    // });
    // builder.addCase(trunkSetUpdateTemplateId.fulfilled, (state, req) => {
    //   state.updateTemplateId = req.meta.arg;
    // });
  },
});
export const mailTemplateActions = mailTemplateSlice.actions;

export default mailTemplateSlice.reducer;
