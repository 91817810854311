import {
  Box,
  Button,
  Icons,
  Modal,
  Select,
  TextField,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import { useEffect, useState, useTransition } from "react";
import useDeptStore from "../../redux/dispatcher/useDeptStore";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import utilsCommon from "../../shared/utils/common";
import { SelectItem } from "fasoo-ui-component-next/src/components/atom/Select";
import { SimpleTreeNode } from "../../types/User";

interface CreateUpdateUserModalProps {
  isOpen: boolean;
  type: string;
  parentCode: string;
  parentName: string;
  deptCode?: string;
  deptName?: string;
  onClose: () => void;
}

const CreateUpdateDeptModal: React.FC<CreateUpdateUserModalProps> = ({
  isOpen,
  parentCode,
  parentName,
  type,
  deptCode,
  deptName,
  onClose,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { deptState, addDept, updateDept, getDeptList } = useDeptStore();
  const [tempDeptName, changeTempDeptName] = useState(deptName ?? "");
  const [tempDeptCode, changeTempDeptCode] = useState(deptCode ?? "");
  const [deptList, chnageDeptList] = useState<SimpleTreeNode[]>([]);
  const [tempParentCode, changeTempParentCode] = useState(parentCode ?? "");
  useEffect(() => {
    chnageDeptList(deptState.organList);
  }, [deptState.organList]);
  const handleAddDept = async () => {
    if (
      tempDeptCode.trim() &&
      !utilsCommon.deptCodeValidation(tempDeptCode.trim())
    ) {
      toast.toastMsg(nanoid(), t("dept.msg.invalidCodeMsg"), "error");
      return;
    }
    if (
      tempDeptName.trim() &&
      !utilsCommon.deptCodeValidation(tempDeptName.trim())
    ) {
      toast.toastMsg(nanoid(), t("dept.msg.invalidNameMsg"), "error");
      return;
    }
    if (tempDeptCode.trim().length > 64) {
      toast.toastMsg(nanoid(), t("dept.msg.codeLengthLimitMsg"), "error");
      return;
    }
    if (tempDeptName.trim().length > 128) {
      toast.toastMsg(nanoid(), t("dept.msg.namdLengthLimitMsg"), "error");
      return;
    }
    let resp: any =
      type === "CREATE"
        ? await addDept({
            deptCode: tempDeptCode.trim(),
            deptName: tempDeptName.trim(),
            parentCode: parentCode,
          })
        : await updateDept({
            deptCode: tempDeptCode.trim(),
            deptName: tempDeptName.trim(),
            parentCode: tempParentCode.trim(),
          });
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        type === "CREATE"
          ? t("dept.msg.addSuccessMsg")
          : t("dept.msg.updateSuccessMsg"),
        "success"
      );
      getDeptList();
      onClose();
    } else {
      toast.toastMsg(
        nanoid(),
        resp?.payload.code && resp?.payload.code === "DATA_DUPLICATE"
          ? t("dept.msg.existedCodeMsg")
          : t("dept.msg.addFailMsg"),
        "error"
      );
    }
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={
        type === "CREATE"
          ? t("dept.deptAddTitle")
          : t("dept.deptUpdateInfoTitle")
      }
      width={"800px"}
      isDivider={false}
      footer={
        <Box justifyContent="flex-end">
          <Button color={"secondary"} onClick={onClose}>
            {t("common.button.cancel")}
          </Button>
          <Button
            classes="mg ml-4"
            disabled={!tempDeptName || tempDeptName?.trim() === ""}
            onClick={handleAddDept}
          >
            {type === "CREATE"
              ? t("common.button.add")
              : t("common.button.update")}
          </Button>
        </Box>
      }
    >
      <Box direction="column" width={"100%"}>
        <Box width={"100%"}>
          <Box direction="column" width={"50%"}>
            <Typography isBold>{t("dept.deptCode")}</Typography>
            <TextField
              fullWidth
              placeholder={t("dept.msg.undefinedCodeMsg")}
              classes="mg mt-12"
              size={"lg"}
              text={tempDeptCode}
              hasText
              maxLength={64}
              disabled={type === "UPDATE"}
              onChange={(e) => changeTempDeptCode(e.target.value)}
            />
            <Box classes="mg mt-8">
              <Icons variant="common" label="info" stroke="#717985" />
              <Typography
                size={12}
                lineHeight="normal"
                color={"secondary"}
                classes="mg ml-4"
              >
                {t("dept.msg.codeRandomMsg")}
              </Typography>
            </Box>
          </Box>
          <Box direction="column" width={"50%"} classes="mg ml-24">
            <Typography isBold>{t("dept.deptName")}</Typography>
            <TextField
              fullWidth
              placeholder={t("dept.msg.undefinedNameMsg")}
              classes="mg mt-12"
              size={"lg"}
              text={tempDeptName}
              hasText
              maxLength={128}
              onChange={(e) => changeTempDeptName(e.target.value)}
            />
          </Box>
        </Box>
        {type === "UPDATE" && deptCode !== "COMPANY" && (
          <Box direction="column" width={"50%"} classes="mg mt-12">
            <Typography isBold>{t("dept.deptHighLevelDept")}</Typography>
            <Select
              onClick={(e) => changeTempParentCode(e)}
              classes="mg mt-12"
              size="md"
              height={40}
              isWidthFull
              selectedEventKey={tempParentCode}
            >
              {deptList
                .filter(
                  (item1) =>
                    item1.deptCode !== deptCode && item1.type === "dept"
                )
                .map((item) => (
                  <SelectItem eventKey={item.deptCode}>
                    {item.deptName}
                  </SelectItem>
                ))}
            </Select>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default CreateUpdateDeptModal;
