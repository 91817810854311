import { Avatar, Box, Typography } from "fasoo-ui-component-next";
import useReportRankStore from "../../../redux/dispatcher/useReportRankStore";
import { useEffect, useState } from "react";
import utilsCommon from "../../../shared/utils/common";
import { ReportStateType } from "../../../types/ReportRank";
import Highcharts, { TooltipFormatterContextObject } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
interface StaticsPageProps {
  period?: {
    value: string;
    start: number;
    end: number;
  };
}
const StaticsPage: React.FC<StaticsPageProps> = ({ period }) => {
  const { t, i18n } = useTranslation();
  const { reportRankState } = useReportRankStore();
  const [reportStatInfo, changeReportStatInfo] = useState<ReportStateType>({
    totalStat: {
      receivedCnt: 0,
      reportCnt: 0,
      spamCnt: 0,
    },
    dateStat: [],
    reportStat: [],
    spamStat: [],
  });
  const [lineOptions, setLineOptions] = useState<any>([]);

  useEffect(() => {
    setLineOptions({
      chart: {
        type: "spline",
        width: document.getElementById("reportStats")?.clientWidth ?? 1400,
      },
      title: {
        text: "",
      },
      legend: {
        align: "left",
        verticalAlign: "top",
        margin: 30,
        itemStyle: {
          fontSize: "14px",
        },
      },
      xAxis: {
        categories: reportStatInfo?.dateStat
          .map((item) => utilsCommon.getShortDate(item.date))
          .reverse(),
        title: {
          text: t("common.time.date"),
          align: "high",
          style: {
            fontSize: "14px",
          },
        },
        labels: {
          style: {
            fontSize: "14px",
          },
        },
      },
      yAxis: {
        title: {
          text: t("program.programDataUnit"),
          align: "high",
          rotation: 0,
          labels: {
            style: {
              fontSize: "14px",
            },
          },
        },
        style: {
          fontSize: "14px",
        },
        labels: {
          style: {
            fontSize: "14px",
          },
        },
        tickInterval: 50,
      },
      plotOptions: {
        series: {
          allowPointSelect: false,
          events: {},
        },
      },
      tooltip: {
        shared: true,
        style: {
          fontSize: "12px",
        },
        pointFormatter: function (this: TooltipFormatterContextObject): string {
          return `<span style="color:${this.color}">\u25CF</span> ${
            this.series.name
          }: <b>${this.y} ${t("program.programDataUnit")}</b><br/>`;
        },
      },
      credits: { enabled: false },
      series: [
        // {
        //   name: t("report.stats.reportReportReceived"),
        //   data: reportStatInfo?.dateStat
        //     .map((item) => item.receivedCnt)
        //     .reverse(),
        //   color: "#55a3ee",
        // },

        {
          name: t("report.stats.reportTrainingMailReport"),
          data: reportStatInfo?.dateStat
            .map((item) => item.reportCnt)
            .reverse(),
          color: "#27dbd8",
        },
        {
          name: t("report.stats.reportSuspiciousMail"),
          data: reportStatInfo?.dateStat
            .map((item) => item.receivedCnt - item.reportCnt)
            .reverse(),
          color: "#ff9700",
        },
        {
          name: t("report.stats.reportActualMaliciousMail"),
          data: reportStatInfo?.dateStat.map((item) => item.spamCnt).reverse(),
          color: "#fa504b",
        },
      ],
    });
  }, [reportStatInfo, i18n.language]);
  useEffect(() => {
    changeReportStatInfo(reportRankState?.reportPeriod);
  }, [reportRankState.reportPeriod]);
  const DATE_RESOURCE: { [key: string]: string } = {
    PERIOD_6MONTH: t("report.stats.reportRecent6Month"),
    PERIOD_1YEAR: t("report.stats.reportRecent1Year"),
    PERIOD_CUSTOM: t("graph.phishingCustomScope"),
  };
  return (
    <Box classes="mg mt-20" direction="column" id={"reportStats"}>
      <Box alignItems="center">
        <Typography isBold size={16}>
          {DATE_RESOURCE[period?.value ?? ""]}
        </Typography>
        <Typography size={16} classes="mg ml-4">{`(${utilsCommon.getShortDate(
          period?.start
        )}~${utilsCommon.getShortDate(period?.end)})`}</Typography>
      </Box>
      <Box classes="mg mt-32" justifyContent="space-evenly" direction="column">
        <Box justifyContent="space-between" width={"100%"}>
          <Box
            classes="dashboard__box blue__box"
            width={"24%"}
            direction="column"
          >
            <Typography size={12} classes="mg mb-4" color="white">
              {t("report.stats.reportReportReceived")}
            </Typography>
            <Box alignItems="center">
              {/* <Typography color="white" classes="mg mr-8">
                총
              </Typography> */}
              <Typography size={24} fontWeight={700} color="white">
                {reportStatInfo?.totalStat.receivedCnt}
              </Typography>
              <Typography color="white" classes="mg ml-8">
                {t("program.programDataUnit")}
              </Typography>
            </Box>
          </Box>
          <Box
            classes="dashboard__box mint__box"
            width={"24%"}
            direction="column"
          >
            <Typography size={12} classes="mg mb-4" color="white">
              {t("report.stats.reportTrainingMailReport")}
            </Typography>
            <Box alignItems="center">
              <Typography size={24} fontWeight={700} color="white">
                {reportStatInfo?.totalStat.reportCnt}
              </Typography>
              <Typography color="white" classes="mg ml-8">
                {t("program.programDataUnit")}
              </Typography>
            </Box>
          </Box>
          <Box
            classes="dashboard__box yellow__box"
            width={"24%"}
            direction="column"
          >
            <Typography size={12} classes="mg mb-4" color="white">
              {t("report.stats.reportSuspiciousMail")}
            </Typography>
            <Box alignItems="center">
              <Typography size={24} fontWeight={700} color="white">
                {reportStatInfo?.totalStat.receivedCnt -
                  reportStatInfo?.totalStat.reportCnt}
              </Typography>
              <Typography color="white" classes="mg ml-8">
                {t("program.programDataUnit")}
              </Typography>
            </Box>
          </Box>
          <Box
            classes="dashboard__box red__box"
            width={"24%"}
            direction="column"
          >
            <Typography size={12} classes="mg mb-4" color="white">
              {t("report.stats.reportActualMaliciousMail")}
            </Typography>
            <Box alignItems="center">
              <Typography size={24} fontWeight={700} color="white">
                {reportStatInfo?.totalStat.spamCnt}
              </Typography>
              <Typography color="white" classes="mg ml-8">
                {t("program.programDataUnit")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box direction="column" width={"100%"} classes="mg mt-40">
          <Box alignItems="flex-end">
            <Typography size={16} fontWeight={700}>
              {t("report.reportMailStatus")}
            </Typography>
          </Box>
          <Box>
            <HighchartsReact highcharts={Highcharts} options={lineOptions} />
          </Box>
        </Box>
        <Box classes="mg mt-40 mb-40" width={"100%"}>
          <Box direction="column" width={"50%"}>
            <Box alignItems="flex-end">
              <Typography size={16} fontWeight={700}>
                {t("report.stats.reportAllReportTop10")}
              </Typography>
            </Box>
            <Box classes="mg mt-16" direction="column">
              {reportStatInfo?.reportStat?.map((stat, index) => (
                <Box
                  justifyContent="space-between"
                  alignItems="center"
                  height={52}
                >
                  <Box alignItems="center">
                    <Typography width="28px">
                      {" "}
                      {(index < 9 ? "0" : "") + (index + 1)}
                    </Typography>
                    <Avatar name={stat.name} userId={stat.email}></Avatar>
                    <Typography classes="mg ml-8">
                      {stat.name} ({stat.email})
                    </Typography>
                  </Box>
                  <Typography>
                    {stat.reportCnt} {t("program.programDataUnit")}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box direction="column" width={"50%"} classes="mg ml-40">
            <Box alignItems="flex-end">
              <Typography size={16} fontWeight={700}>
                {t("report.stats.reportMaliciousReportTop10")}
              </Typography>
            </Box>
            <Box classes="mg mt-16" direction="column">
              {reportStatInfo?.spamStat?.map((stat, index) => (
                <Box
                  justifyContent="space-between"
                  alignItems="center"
                  height={52}
                >
                  <Box alignItems="center">
                    <Typography width="28px">
                      {(index < 9 ? "0" : "") + (index + 1)}
                    </Typography>
                    <Avatar name={stat.name} userId={stat.email}></Avatar>
                    <Typography classes="mg ml-8">
                      {stat.name} ({stat.email})
                    </Typography>
                  </Box>
                  <Typography>
                    {stat.spamCnt} {t("program.programDataUnit")}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StaticsPage;
