import "./index.scss";
import {
  Accordion,
  AccordionItem,
  Box,
  Button,
  Modal,
  ScrollBox,
  Tag,
  ToolTip,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import { useEffect, useState } from "react";
import useProgramStore from "../../../redux/dispatcher/useProgramStore";
import { ImagesInfo, ProgramDetail } from "../../../types/Info";
import utilsCommon from "../../../shared/utils/common";
import useDeptStore from "../../../redux/dispatcher/useDeptStore";
import { SimpleTreeNode } from "../../../types/User";
import TreeSimple from "../../../component/TreeSimple";
import dayjs from "dayjs";
import CommonModal from "../../../component/CommonModal";
import { nanoid } from "@reduxjs/toolkit";
import useMailTemplateStore from "../../../redux/dispatcher/useMailTemplateStore";
import { Scenario } from "../../../types/Scenario";
import RegitTrainingModal from "./RegitTrainingModal";
import SmallUserChip from "../../../component/SmallUserChip";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MailBody from "../../../component/MailBody";
import useContentStore from "../../../redux/dispatcher/useContentStore";
import useSendHistoryStore from "../../../redux/dispatcher/useSendHistoryStore";

interface DetailTrainingModalProps {
  isOpen: boolean;
  onClose: () => void;
  refreshList: () => void;
  configId: number;
}

const TrainingDetailModal: React.FC<DetailTrainingModalProps> = ({
  isOpen,
  onClose,
  refreshList,
  configId,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();

  const { deptState, getDeptList } = useDeptStore();
  const { programState, getProgramDetail, deleteProgram } = useProgramStore();
  const { mailTemplateState, getMailTemplateList } = useMailTemplateStore();
  const { getImages, contentState } = useContentStore();
  const { sendResultMail } = useSendHistoryStore();

  const [templateDetail, setTemplateDetail] = useState<ProgramDetail>();
  const [organTree, changeOrganTree] = useState<SimpleTreeNode[] | undefined>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  // const{}
  const userCheckGroup = useCheckboxGroup<string>();
  const deptCheckGroup = useCheckboxGroup<string>();

  useEffect(() => {
    getProgramDetail(configId);
    getDeptList();
    getImages({});
  }, []);
  useEffect(() => {
    changeOrganTree(deptState.organList);
  }, [deptState.organList]);

  useEffect(() => {
    if (programState.configDetail) {
      setTemplateDetail(programState.configDetail);
      // if (programState.configDetail.templateList.length === 0) {
      getMailTemplateList({});
      // }
      deptCheckGroup.handleAllClick(
        programState.configDetail.trainingInfo.deptIds
      );
      initializeUserCheckbox();
      changeOrganTree(
        deptState.organList?.filter((item: SimpleTreeNode) =>
          [
            programState.configDetail.trainingInfo.deptIds,
            programState.configDetail.trainingInfo.userIds,
          ].includes(item.deptCode)
        )
      );
    }
  }, [programState.configDetail]);
  const initializeUserCheckbox = async () => {
    let resp: any = await getDeptList(
      programState.configDetail.trainingInfo.deptIds
    );
    if (resp.payload) {
      let newUsers =
        resp.payload?.data?.list?.map(
          (item: SimpleTreeNode) => item.deptCode
        ) ?? [];
      userCheckGroup.handleAllClick([
        ...programState.configDetail.trainingInfo.userIds,
        ...newUsers,
      ]);
    }
  };
  const onDelete = async () => {
    const resp: any = await deleteProgram(configId);

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programDeleteSuccessMsg"),
        "success"
      );
    } else {
      toast.toastMsg(nanoid(), t("program.msg.programDeleteFailMsg"), "error");
    }

    setIsDeleteModalOpen(false);
    onClose();
  };

  const [parsedSendTime, setParsedSendTime] = useState<{
    sendStartTime: string | null;
    sendEndTime: string | null;
  }>({
    sendStartTime: null,
    sendEndTime: null,
  });

  const parseAndSubtractHours = (time: string, hours: number): string => {
    const [hh, mm] = time.split(":").map(Number);
    const date = new Date();
    date.setHours(hh, mm, 0, 0);
    date.setHours(date.getHours() + hours);
    const newHH = date.getHours().toString().padStart(2, "0");
    const newMM = date.getMinutes().toString().padStart(2, "0");
    return `${newHH}:${newMM}`;
  };

  const [isResultMailModalOpen, setIsResultMailModalOpen] = useState(false);

  const onSendResultMail = async (configId: number) => {
    const resp: any = await sendResultMail(configId);

    if (resp.payload && resp.payload.success === true) {
      toast.toastMsg(
        nanoid(),
        t("program.msg.sendResultMailSuccessMsg"),
        "success"
      );
    } else {
      toast.toastMsg(nanoid(), t("program.msg.sendResultMailFailMsg"), "error");
    }

    setIsResultMailModalOpen(false);
  };

  useEffect(() => {
    if (templateDetail?.trainingInfo.sendStartTime) {
      const tempStartTime = templateDetail.trainingInfo.sendStartTime;
      const tempEndTime = templateDetail.trainingInfo.sendEndTime;

      const newSendStartTime = parseAndSubtractHours(
        tempStartTime as string,
        9
      );
      const newSendEndTime = parseAndSubtractHours(tempEndTime as string, 9);

      setParsedSendTime({
        sendStartTime: newSendStartTime,
        sendEndTime: newSendEndTime,
      });
    }
  }, [templateDetail?.trainingInfo]);

  const infoBody = () => {
    return (
      <Box direction="column" classes="mg mt-8 ml-10 mr-10">
        <Box direction="column">
          <Typography fontWeight={700}>{t("program.programName")}</Typography>
          <Box classes="mg mt-12" alignItems="center">
            {templateDetail?.trainingInfo.realTrainingFlag ? (
              <Tag
                name={t("program.realTrainingLabel")}
                isBgFilled={false}
                size="ms"
                color="green"
                radius={6}
              />
            ) : (
              <Tag
                name={t("program.mockTrainingLabel")}
                isBgFilled={false}
                size="ms"
                color="gray"
                radius={6}
              />
            )}
            <Typography classes="mg ml-8 " ellipsis lineHeight="normal">
              {templateDetail?.trainingInfo.configName}
            </Typography>
          </Box>
        </Box>
        <Box width={"100%"}>
          <Box classes="mg mt-32" direction="column" width={"34%"}>
            <Typography fontWeight={700}>
              {t("program.trainingDuration")}
            </Typography>
            <Typography classes="mg mt-12" lineHeight="1.5">
              {`${utilsCommon.getFullDateStr(
                templateDetail?.trainingInfo.sendStartDate + "+00:00"
              )} ~ ${utilsCommon.getFullDateStr(
                templateDetail?.trainingInfo.sendEndDate + "+00:00"
              )}`}
            </Typography>
          </Box>

          <Box classes="mg mt-32 ml-20" direction="column" width={"32%"}>
            <Typography fontWeight={700}>
              {t("program.sendingMailTime")}
            </Typography>
            <Typography classes="mg mt-12">
              {templateDetail?.trainingInfo.sendStartTime
                ? `${parsedSendTime.sendStartTime} ~ ${parsedSendTime.sendEndTime}`
                : t("program.programAllDay")}
            </Typography>
          </Box>
          <Box classes="mg mt-32 ml-20" direction="column" width={"32%"}>
            <Typography fontWeight={700}>{t("program.sendMailCnt")}</Typography>
            <Typography classes="mg mt-12">
              {t("program.sendCnt", {
                length: templateDetail?.trainingInfo.sendMailCount,
              })}
            </Typography>
          </Box>
        </Box>
        <Box width={"100%"}>
          <Box classes="mg mt-32 mb-16" direction="column" width={"34%"}>
            <Typography fontWeight={700}>
              {t("program.isActivateFlag")}
            </Typography>
            <Typography classes="mg mt-12">
              {templateDetail?.trainingInfo.activateFlag
                ? t("program.activated")
                : t("program.deactivated")}
            </Typography>
          </Box>{" "}
          <Box classes="mg mt-32 ml-20" direction="column" width={"32%"}>
            <Typography fontWeight={700}>
              {t("reply.isActivateAutoReply")}
            </Typography>
            <Typography classes="mg mt-12">
              {templateDetail?.trainingInfo.autoReplyFlag
                ? t("program.activated")
                : t("program.deactivated")}
            </Typography>
          </Box>
          <Box classes="mg mt-32 ml-20" direction="column" width={"32%"}>
            <Typography fontWeight={700}>
              {t("program.progressStatus")}
            </Typography>
            <Typography classes="mg mt-12">
              {/* {`${
                templateDetail?.trainingInfo.sendCnt !== 0 &&
                templateDetail?.trainingInfo.sendCnt !== undefined &&
                templateDetail?.trainingInfo.sendMailCount !== 0 &&
                templateDetail?.trainingInfo.sendMailCount !== undefined &&
                  ? (templateDetail?.trainingInfo.sendCnt * 100) /
                    templateDetail?.trainingInfo.sendMailCount
                  : 0
              } % (${templateDetail?.trainingInfo.sendCnt} / ${
                templateDetail?.trainingInfo.sendMailCount *
                templateDetail?.trainingInfo.userCount
              }${t("program.programDataUnit")})`} */}

              {/* {templateDetail?.trainingInfo.sendCnt !== 0 &&
              templateDetail?.trainingInfo.sendCnt !== undefined &&
              templateDetail?.trainingInfo.sendMailCount !== 0 &&
              templateDetail?.trainingInfo.sendMailCount !== undefined &&
              templateDetail?.trainingInfo.userCount !== 0 &&
              templateDetail?.trainingInfo.userCount !== undefined
                ? `${
                    (templateDetail?.trainingInfo.sendCnt /
                      (templateDetail?.trainingInfo.sendMailCount *
                        templateDetail?.trainingInfo.userCount)) *
                    100
                  } %  `
                : `0 %`} */}
              {templateDetail?.trainingInfo?.sendCnt === 0
                ? templateDetail?.trainingInfo?.startedFlag ||
                  (templateDetail?.trainingInfo?.sendStartDate &&
                    dayjs(
                      templateDetail?.trainingInfo?.sendStartDate + "+00:00"
                    ).valueOf() < dayjs().valueOf())
                  ? "0%"
                  : t("program.programSendWait")
                : templateDetail?.trainingInfo?.sendCnt &&
                  templateDetail?.trainingInfo?.userCount &&
                  templateDetail?.trainingInfo?.sendMailCount
                ? `${(
                    (templateDetail?.trainingInfo?.sendCnt /
                      (templateDetail?.trainingInfo?.userCount *
                        templateDetail?.trainingInfo?.sendMailCount)) *
                    100
                  ).toFixed(0)} %`
                : t("program.programSendWait")}
              {templateDetail?.trainingInfo.sendMailCount !== 0 &&
                templateDetail?.trainingInfo.sendMailCount !== undefined &&
                templateDetail?.trainingInfo.userCount !== 0 &&
                templateDetail?.trainingInfo.userCount !== undefined &&
                `(${templateDetail?.trainingInfo.sendCnt} / ${
                  templateDetail?.trainingInfo.sendMailCount *
                  templateDetail?.trainingInfo.userCount
                } ${t("program.programDataUnit")})`}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };
  const userBody = () => {
    return (
      <ScrollBox maxHeight={307} classes="mg mt-8 ml-10 mr-10 mb-16">
        {templateDetail?.userList?.length &&
        templateDetail?.userList?.length > 0 ? (
          <Box width={"100%"} classes="gridList">
            {templateDetail?.userList.map((user) => (
              <SmallUserChip
                userId={user.userEmail ?? ""}
                userName={user.userName}
                classes="mg mr-4 "
              />
            ))}
          </Box>
        ) : (
          <>
            {organTree && (
              <TreeSimple
                rootCode={"COMPANY"}
                treeNodes={organTree}
                depth={0}
                disableCheck
                checkUserIds={userCheckGroup.selected}
                checkDeptIds={deptCheckGroup.selected}
                checkboxUserGroup={userCheckGroup}
                checkboxDeptGroup={deptCheckGroup}
                isUserCheck
                isDeptCheck
                classes="mg mt-8"
              />
            )}
          </>
        )}
      </ScrollBox>
    );
  };
  const templateBody = () => {
    return (
      <ScrollBox maxHeight={307} classes="mg mt-8 ml-10 mr-10 gridList">
        {templateDetail?.templateList.length &&
        templateDetail?.templateList.length > 0
          ? templateDetail?.templateList.map((template, index) => {
              const targetTemplate = mailTemplateState.mailTemplateList.filter(
                (item: Scenario) => item.templateId === template.templateId
              )[0];

              return (
                <Box classes="mg mr-4 scenario__tag" key={template.templateId}>
                  <ToolTip
                    message={
                      targetTemplate ? (
                        <>
                          <Box classes="mg mb-8">
                            <Typography
                              color="light"
                              maxWidth={240}
                              classes="scenario__tooltip__template"
                            >
                              {template.templateName}
                            </Typography>
                          </Box>
                          <MailBody
                            body={
                              targetTemplate.imageId > 0
                                ? targetTemplate.templateHtml.replaceAll(
                                    "##IMAGE##",
                                    contentState.images.filter(
                                      (item: ImagesInfo) =>
                                        item.imageId === targetTemplate.imageId
                                    )[0]?.imagePath ?? "##IMAGE##"
                                  )
                                : targetTemplate.templateHtml
                            }
                            mailWidth={240}
                            height={200}
                            allowScroll={false}
                            id={index}
                          />
                        </>
                      ) : (
                        t("template.deletedScenario")
                      )
                    }
                  >
                    {targetTemplate ? (
                      <Typography ellipsis>{template.templateName}</Typography>
                    ) : (
                      <Typography classes="deleted__scenario">
                        {t("template.deletedScenario")}
                      </Typography>
                    )}
                  </ToolTip>
                </Box>
              );
            })
          : templateDetail?.trainingInfo.templateIds?.map(
              (templateId, index) => {
                const targetTemplate =
                  mailTemplateState.mailTemplateList.filter(
                    (item: Scenario) => item.templateId === parseInt(templateId)
                  )[0];
                return (
                  <Box classes="mg mr-4 scenario__tag" key={templateId}>
                    <ToolTip
                      // backgroundColor="white"
                      message={
                        targetTemplate?.templateHtml ? (
                          <>
                            <Box classes="mg mb-8">
                              <Typography
                                color="light"
                                classes="scenario__tooltip__template"
                                maxWidth={240}
                              >
                                {targetTemplate.templateName}
                              </Typography>
                            </Box>
                            <MailBody
                              body={
                                targetTemplate.imageId > 0
                                  ? targetTemplate.templateHtml.replaceAll(
                                      "##IMAGE##",
                                      contentState.images.filter(
                                        (item: ImagesInfo) =>
                                          item.imageId ===
                                          targetTemplate.imageId
                                      )[0]?.imagePath ?? "##IMAGE##"
                                    )
                                  : targetTemplate.templateHtml
                              }
                              mailWidth={240}
                              height={200}
                              allowScroll={false}
                              id={index}
                            />
                          </>
                        ) : (
                          t("template.deletedScenario")
                        )
                      }
                    >
                      {targetTemplate ? (
                        <Typography ellipsis maxWidth={250}>
                          {targetTemplate.templateName}
                        </Typography>
                      ) : (
                        <Typography classes="deleted__scenario">
                          {t("template.deletedScenario")}
                        </Typography>
                      )}
                    </ToolTip>
                  </Box>
                );
              }
            )}
      </ScrollBox>
    );
  };
  return (
    <>
      {isResultMailModalOpen && (
        <Modal
          open={isResultMailModalOpen}
          title={t("program.sendResultMail")}
          isDivider={false}
          footer={
            <Box justifyContent="flex-end">
              <Button
                color="secondary"
                onClick={() => setIsResultMailModalOpen(false)}
              >
                {t("common.button.close")}
              </Button>
              <Button
                classes="mg ml-8"
                onClick={() =>
                  templateDetail?.trainingInfo?.configId &&
                  onSendResultMail(templateDetail?.trainingInfo?.configId)
                }
              >
                확인
              </Button>
            </Box>
          }
        >
          {t("program.msg.resultMailConfirmMsg")}
        </Modal>
      )}
      <Modal
        open={isOpen}
        title={t("program.detail")}
        titleFontSize={256}
        onClose={onClose}
        width={960}
        isDivider={false}
        contentHeight={700}
        classes="training_detail"
        footer={
          <Box justifyContent="space-between">
            <Box>
              {dayjs(
                templateDetail?.trainingInfo.sendStartDate + "+00:00"
              ).valueOf() < dayjs().valueOf() && (
                <Button
                  color="secondary"
                  onClick={() => {
                    navigate(`/manageTrain/result`);
                    window.sessionStorage.setItem(
                      "resultFilter",
                      `configId=${templateDetail?.trainingInfo?.configId}`
                    );
                  }}
                >
                  {t("program.sendAndResultFlag")}
                </Button>
              )}
              {/* {templateDetail?.trainingInfo.sendCount === 0 && ( */}
              {!(
                (templateDetail?.trainingInfo?.userCount &&
                  templateDetail?.trainingInfo?.sendMailCount &&
                  templateDetail?.trainingInfo.sendCnt ===
                    templateDetail?.trainingInfo?.userCount *
                      templateDetail?.trainingInfo?.sendMailCount) ||
                dayjs(
                  templateDetail?.trainingInfo.sendEndDate + "+00:00"
                ).valueOf() < dayjs().valueOf()
              ) && (
                <Button
                  color="activeGreen"
                  classes="mg ml-8"
                  text={t("common.button.update")}
                  onClick={() => setIsEditModalOpen(true)}
                />
              )}
              {((templateDetail?.trainingInfo?.userCount &&
                templateDetail?.trainingInfo?.sendMailCount &&
                templateDetail?.trainingInfo.sendCnt ===
                  templateDetail?.trainingInfo?.userCount *
                    templateDetail?.trainingInfo?.sendMailCount) ||
                dayjs(
                  templateDetail?.trainingInfo.sendEndDate + "+00:00"
                ).valueOf() < dayjs().valueOf()) && (
                <Button
                  color="secondary"
                  classes="mg ml-8"
                  text={t("program.sendingTrainingResultMail")}
                  onClick={() =>
                    templateDetail?.trainingInfo?.configId &&
                    // sendResultMail(templateDetail?.trainingInfo?.configId)
                    setIsResultMailModalOpen(true)
                  }
                />
              )}
              {/* )} */}
              {!templateDetail?.trainingInfo?.startedFlag &&
                dayjs(
                  templateDetail?.trainingInfo.sendStartDate + "+00:00"
                ).valueOf() > dayjs().valueOf() && (
                  <Button
                    color="lightWarning"
                    classes="mg ml-8"
                    onClick={() => setIsDeleteModalOpen(true)}
                    text={t("program.delete")}
                  />
                )}
            </Box>

            <Button onClick={onClose}>{t("common.button.close")}</Button>
          </Box>
        }
      >
        {isEditModalOpen && (
          <RegitTrainingModal
            isOpen={isEditModalOpen}
            refreshList={refreshList}
            onClose={() => {
              getProgramDetail(configId);
              setIsEditModalOpen(false);
            }}
            configId={templateDetail?.trainingInfo.configId}
          />
        )}
        {templateDetail ? (
          <Box direction="column" width={"100%"}>
            <Box width={"100%"}>
              <Accordion style={{ width: "100%" }}>
                <AccordionItem
                  title={t("program.basicInfo")}
                  expandedProp={true}
                  type={"CLOSEINFO"}
                  classes="training__accordian"
                >
                  {infoBody()}
                </AccordionItem>
              </Accordion>
            </Box>
            <Box width={"100%"} classes="mg mt-2">
              <Accordion style={{ width: "100%" }}>
                <AccordionItem
                  title={t("dashboard.user")}
                  title2={
                    (templateDetail.userList?.length > 0
                      ? templateDetail.userList?.length
                      : templateDetail.trainingInfo.userIds?.length) + ""
                  }
                  expandedProp={true}
                  type={"CLOSEINFO"}
                  classes="training__accordian"
                >
                  {userBody()}
                </AccordionItem>
              </Accordion>
            </Box>
            <Box width={"100%"} classes="mg mt-2">
              <Accordion style={{ width: "100%" }}>
                <AccordionItem
                  title={t("template.templateSimple")}
                  title2={
                    (templateDetail?.templateList.length === 0
                      ? templateDetail?.trainingInfo.templateIds?.length
                      : templateDetail?.templateList.length) + ""
                  }
                  expandedProp={true}
                  type={"CLOSEINFO"}
                  classes="training__accordian"
                >
                  {templateBody()}
                </AccordionItem>
              </Accordion>
            </Box>

            <CommonModal
              open={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              title={t("program.delete")}
              afterSubmitButton={onDelete}
              subMessage={t("program.msg.deleteConfirmMsg")}
              type="delete"
            />
          </Box>
        ) : (
          <div>Loading</div>
        )}
      </Modal>
    </>
  );
};

export default TrainingDetailModal;
