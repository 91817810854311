import Axios from "axios";
import { getAccessToken } from "./auth";
import i18n from "../../locales";

const axiosBlob = Axios.create({
  withCredentials: true,
  headers: {
    crossDomain: true,
    "Content-Type": "application/json",
    Accept: "application/octet-stream",
    "Accept-Language": sessionStorage.getItem("mindsat-lang") ?? i18n.language,
    Authorization:
      localStorage.getItem("accessToken") !== null &&
      `Bearer ${localStorage.getItem("accessToken")}`,
  },
  responseType: "blob",
});

axiosBlob.interceptors.request.use(function (config) {
  const accessToken = getAccessToken();

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
    config.headers["Accept-Language"] =
      sessionStorage.getItem("mindsat-lang") ?? i18n.language;
  } else {
    delete config.headers.Authorization;
  }
  return config;
});
axiosBlob.interceptors.response.use(
  (res) => {
    if (res.data && res.data?.success === false) alert("Fail");
    return res;
  },
  (err) => {
    alert("Fail");
    if (
      err.response &&
      (err.response.status === 401 || err.response.status === 400)
    ) {
      // logout
      localStorage.removeItem("accessToken");
      delete axiosBlob.defaults.headers.common["Authorization"];
      delete axiosBlob.defaults.headers["Authorization"];
    }
    return Promise.reject(err);
  }
);

export default axiosBlob;
