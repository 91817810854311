import config from "../../shared/config/config";
import axios from "../../shared/utils/axios";
import axiosForm from "../../shared/utils/axiosForm";
import { BaseResponseBean, BaseResponseDTO } from "../../types/Common";
import {
  MailTemplate,
  MailTemplateInfo,
  MailTemplateInsertRequest,
  MailTemplateUpdateRequest,
} from "../../types/Info";
import {
  ParsedEMLReqDto,
  Scenario,
  ScenarioListReqDto,
  ScenarioListResDto,
} from "../../types/Scenario";
import { UsedTemplate } from "../../types/Send";

const GATEWAY_URL = config.servers.gateway.url;

interface MailTemplateApi {
  getMailTemplateList(
    req: ScenarioListReqDto
  ): Promise<BaseResponseDTO<ScenarioListResDto>>;
  getMailTemplateInfo(): Promise<MailTemplateInfo>;
  insertMailTemplate(req: {
    request: MailTemplateInsertRequest;
    file: File;
  }): Promise<BaseResponseDTO<Scenario>>;
  updateMailTemplate(req: {
    request: MailTemplateUpdateRequest;
    file?: File;
  }): Promise<BaseResponseDTO<Scenario>>;
  getUsedTemplate(): Promise<UsedTemplate[]>;
  parseEMLFile(req: { file: any }): Promise<BaseResponseDTO<ParsedEMLReqDto>>;
  getMailTemplateData(index: number): Promise<BaseResponseDTO<Scenario>>;
  deleteMailTemplate(req: {
    request: {
      templateIds: number[];
    };
  }): Promise<BaseResponseDTO<Scenario>>;
}
class MailTemplateServerApi implements MailTemplateApi {
  async getMailTemplateList(
    req: ScenarioListReqDto
  ): Promise<BaseResponseDTO<ScenarioListResDto>> {
    // const res = await axios.post<MailTemplate[]>(`/getMailTemplateList`, {});
    const res = await axios.post<BaseResponseDTO<ScenarioListResDto>>(
      `${GATEWAY_URL}/api/v2/scenario/list`,
      req
    );
    return Promise.resolve(res.data);
  }
  async getMailTemplateInfo(): Promise<MailTemplateInfo> {
    const res = await axios.post<MailTemplateInfo>(
      `/getMailTemplateList/getInfo`,
      {}
    );
    return Promise.resolve(res.data);
  }
  async insertMailTemplate(req: {
    request: MailTemplateInsertRequest;
    file: File;
  }): Promise<BaseResponseDTO<Scenario>> {
    let formData = new FormData();
    const requestBlob = new Blob([JSON.stringify(req.request)], {
      type: "application/json",
    });
    formData.append("request", requestBlob);
    formData.append("file", req.file);
    const res = await axiosForm.post<BaseResponseDTO<Scenario>>(
      `${GATEWAY_URL}/api/v2/scenario/insert`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return Promise.resolve(res.data);
  }
  async updateMailTemplate(req: {
    request: MailTemplateUpdateRequest;
    file?: File;
  }): Promise<BaseResponseDTO<Scenario>> {
    let formData = new FormData();
    const requestBlob = new Blob([JSON.stringify(req.request)], {
      type: "application/json",
    });
    formData.append("request", requestBlob);
    if (req.file) formData.append("file", req.file);

    const res = await axiosForm.post<BaseResponseDTO<Scenario>>(
      `${GATEWAY_URL}/api/v2/scenario/update`,
      formData
    );
    return Promise.resolve(res.data);
  }
  async getUsedTemplate(): Promise<UsedTemplate[]> {
    const res = await axios.get<UsedTemplate[]>(`/getUsedTemplate`);
    return Promise.resolve(res.data);
  }
  async parseEMLFile(req: {
    file: any;
  }): Promise<BaseResponseDTO<ParsedEMLReqDto>> {
    let formData = new FormData();
    formData.set("file", req.file);

    const res = await axiosForm.post<BaseResponseDTO<ParsedEMLReqDto>>(
      `${GATEWAY_URL}/api/v2/scenario/mail/parsing`,
      formData
    );
    return Promise.resolve(res.data);
  }
  async getMailTemplateData(index: number): Promise<BaseResponseDTO<Scenario>> {
    // const res = await axios.post<MailTemplate>(`/getMailTemplateData/${index}`);

    const res = await axios.post<BaseResponseDTO<Scenario>>(
      `${GATEWAY_URL}/api/v2/scenario/detail`,
      { templateId: index }
    );
    console.log(res.data);
    return Promise.resolve(res.data);
  }
  async deleteMailTemplate(req: {
    request: {
      templateIds: number[];
    };
  }): Promise<BaseResponseDTO<Scenario>> {
    let formData = new FormData();
    const requestBlob = new Blob([JSON.stringify(req.request)], {
      type: "application/json",
    });
    formData.append("request", requestBlob);

    const res = await axios.post<BaseResponseDTO<Scenario>>(
      `${GATEWAY_URL}/api/v2/scenario/delete`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return Promise.resolve(res.data);
  }
}

const mailTemplateApi: MailTemplateApi = new MailTemplateServerApi();

export default mailTemplateApi;
