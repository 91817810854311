import {
  Box,
  Button,
  Icons,
  ToolTip as ToolTip2,
  Typography,
} from "fasoo-ui-component-next";
import "./index.scss";
import useDashboardStore from "../../../redux/dispatcher/useDashboardStore";
import useCommonStore from "../../../redux/dispatcher/useCommonStore";
import useConfigStore from "../../../redux/dispatcher/useConfigStore";
import useProgramStore from "../../../redux/dispatcher/useProgramStore";
import useTraineStaticsStore from "../../../redux/dispatcher/useTraineStaticsStore";
import useSendHistoryStore from "../../../redux/dispatcher/useSendHistoryStore";
import useCustomerStore from "../../../redux/dispatcher/useCustomerStore";
import useCategoryStore from "../../../redux/dispatcher/useCategoryStore";
import { useEffect, useState } from "react";
import {
  Category,
  ImagesInfo,
  MailTemplate,
  PhishingDocument,
  SiteInfo,
  SiteResourceBean,
} from "../../../types/Info";
import useMailTemplateStore from "../../../redux/dispatcher/useMailTemplateStore";
import { ConfigListType, SendHistoryType } from "../../../types/Send";
import useStatisticsStore from "../../../redux/dispatcher/useStatisticsStore";
import useContentStore from "../../../redux/dispatcher/useContentStore";
import useManagerStore from "../../../redux/dispatcher/useManagerStore";
import { ManagerBean } from "../../../types/Manage";
import useAccountStore from "../../../redux/dispatcher/useAccountStore";
import HTMLThumbnail from "./HTMLThumbnail";
import utilsCommon from "../../../shared/utils/common";

const DummyAPIPage: React.FC = () => {
  const { getConfigIds, getMenu, getManager } = useCommonStore();
  const {
    selectCustomer,
    getAccessCompanyList,
    getCustomersList,
    updateCustomerInfo,
    checkCustomerTraining,
    getCustomerCategory,
    getCustomerSetting,
  } = useCustomerStore();
  const {
    categoryState,
    getCategories,
    addCategory,
    updateCategory,
    deleteCategory,
  } = useCategoryStore();
  const {
    getReportHistory,
    getRecentClickHistoryList,
    getProgressList,
    getDashboardReport,
    getSendHistoryList,
    getPhishTypes,
  } = useDashboardStore();
  const { getCustomerName } = useCustomerStore();
  const {
    managerState,
    getManagerList,
    getCommonMangerList,
    addNewManager,
    updateManagerInfo,
    deleteManager,
  } = useManagerStore();
  const { getConfigInfo, getConfigList } = useConfigStore();
  const {
    contentState,
    getAttaches,
    getSites,
    uploadSites,
    updateSites,
    deleteSite,
    getSitesFile,
    getSiteResource,
    insertSiteResource,
    updateFileSiteResource,
    updateSiteResource,
    deleteSiteResource,
    getImages,
    uploadImage,
    updateImage,
    updateFileImage,
    deleteImage,
    updateAttach,
    updateAttachFile,
    downloadAttachFile,
  } = useContentStore();
  const {
    programState,
    getProgramList,
    getProgramDetail,
    updateProgram,
    activateProgram,
    addNewProgram,
    deleteProgram,
  } = useProgramStore();
  const {
    getStaticsList,
    getStaticsTraineGraphListConfig,
    getStaticsTraineGraphListType,
    getStaticsTraineGraphList,
    getPhishingGraph,
  } = useStatisticsStore();
  const {
    getTraineGraphList,
    getTraineGraphListType,
    getTraineGraphListConfig,
    getInpercent,
    getInpercentType,
    getInpercentConfig,
  } = useTraineStaticsStore();
  const {
    sendHistoryState,
    // getSendHistoryById,
    reportSendHistory,
    checkMailExist,
    sendResultMail,
  } = useSendHistoryStore();
  const {
    mailTemplateState,
    getMailTemplateList,
    getMailTemplateInfo,
    insertMailTemplate,
    updateMailTemplate,
    getUsedTemplate,
    parseEMLFile,
    getMailTemplateData,
    deleteMailTemplate,
  } = useMailTemplateStore();
  const { sessionLogOut, changePwd } = useAccountStore();

  const [categoryList, changeCategoryList] = useState([] as Category[]);
  const [sendHistoryList, changeSendHistoryList] = useState(
    [] as SendHistoryType[]
  );
  const [configList, changeConfigList] = useState([] as ConfigListType[]);
  const [mailTemplateList, changeMailTemplateList] = useState(
    [] as MailTemplate[]
  );
  const [siteResources, changeSiteResources] = useState(
    [] as SiteResourceBean[]
  );
  const [managers, changeManagers] = useState([] as ManagerBean[]);
  const [sites, changeSites] = useState([] as SiteInfo[]);
  const [images, changeImaegs] = useState([] as ImagesInfo[]);
  const [attaches, changeAttaches] = useState([] as PhishingDocument[]);

  const DUMMY_CONFIG_ID = 23;
  useEffect(() => {
    changeCategoryList(categoryState.categoryList);
  }, [categoryState.categoryList]);
  useEffect(() => {
    mailTemplateState?.mailTemplateList.map((item: MailTemplate) =>
      getMailTemplateData(item.template_id)
    );
    changeMailTemplateList(mailTemplateState.mailTemplateList);
  }, [mailTemplateState.mailTemplateList.length]);
  useEffect(() => {
    changeMailTemplateList(mailTemplateState.mailTemplateList);
  }, [mailTemplateState.mailTemplateList]);
  useEffect(() => {
    console.log(programState.configList);
    changeConfigList(programState.configList);
  }, [programState.configList]);
  useEffect(() => {
    changeSendHistoryList(sendHistoryState.sendHistory);
  }, [sendHistoryState.sendHistory]);
  useEffect(() => {
    changeSiteResources(contentState.siteResources);
  }, [contentState.siteResources]);
  useEffect(() => {
    changeSites(contentState.sites);
  }, [contentState.sites]);
  useEffect(() => {
    changeImaegs(contentState.images);
  }, [contentState.images]);
  useEffect(() => {
    changeAttaches(contentState.attaches);
  }, [contentState.attaches]);
  useEffect(() => {
    changeManagers(managerState.managerList);
  }, [managerState.managerList]);
  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", () => console.log("change"));
    console.log(
      window.matchMedia,
      window.matchMedia("prefers-color-scheme: light").matches,
      window.matchMedia("prefers-color-scheme: dark").matches
    );
  }, []);
  return (
    <Box classes="main dummyAPI">
      <Box>
        <Icons
          variant="common"
          label={"view"}
          width={20}
          height={20}
          stroke={utilsCommon.getColorInCode("icon3")}
        />
        {window.matchMedia &&
        window.matchMedia("( prefers-color-scheme: dark )").matches
          ? "T"
          : "F"}
      </Box>

      <Box direction="column">
        <Typography size={20} isBold>
          /
        </Typography>
        <Box classes="apis">
          <Button onClick={() => getConfigIds()}>/getConfigIds</Button>
          <Button
            onClick={() => getMenu({ accessType: 1, managerCode: "admin" })}
          >
            /menu
          </Button>
          <Button onClick={() => getManager("admin")}>/manager</Button>
          <Button
            onClick={() =>
              // changePwd({
              //   email: "injubi@fasoo.com",
              //   pwd: "pwdchangeTest",
              // })
              console.log("API Changes")
            }
          >
            /change/pwd
          </Button>
        </Box>
      </Box>
      <Box direction="column">
        <ToolTip2 message={"몇개는 /site"}>
          <Typography size={20} isBold>
            /sites
          </Typography>
        </ToolTip2>

        <Box classes="apis">
          <Button onClick={() => getSites({})}>/</Button>
          <Button
            onClick={() =>
              uploadSites({
                request: { siteName: "테스트" },
                file: new File([], "새 파일~.jpg"),
              })
            }
          >
            /upload
          </Button>
          <Button
            onClick={
              () => (sites.length === 0 ? alert("리스트를 호출해주세요") : null)

              // updateSites({
              //     siteName: "테스트",
              //     file: new File([], "새 파일~.jpg"),
              //     id: sites[0].siteId,
              //   })
            }
          >
            /update
          </Button>
          <Button
            onClick={() =>
              sites.length === 0
                ? alert("리스트를 호출해주세요")
                : getSitesFile({
                    fileName:
                      sites[0].siteUrl.split("/")[
                        sites[0].siteUrl.split("/").length - 1
                      ],
                    site_id: sites[0].siteId,
                  })
            }
          >
            /file
          </Button>
          <Button
            onClick={() =>
              sites.length === 0
                ? alert("리스트를 호출해주세요")
                : deleteSite({
                    request: {
                      siteIds: [sites[0].siteId],
                    },
                  })
            }
          >
            /delete
          </Button>
          <Button onClick={() => getSiteResource()}>/resources</Button>
          <Button
            onClick={
              () =>
                siteResources.length === 0
                  ? alert("리스트를 호출해주세요")
                  : null
              // updateSiteResource({
              //     id: siteResources[siteResources.length - 1].resourceId,
              //     resourceName: "수정수정",
              //   })
            }
          >
            /resource/update
          </Button>
          <Button
            onClick={
              () =>
                siteResources.length === 0
                  ? alert("리스트를 호출해주세요")
                  : null
              // updateFileSiteResource({
              //     id: siteResources[siteResources.length - 1].resourceId,
              //     resourceName: "fff",
              //     file: new File([], "수정된 테스트파일~.jpg"),
              //   })
            }
          >
            /resource/update/file
          </Button>
          <Button
          // onClick={() =>
          //   insertSiteResource({
          //     resourceName: "tetett",
          //     file: new File([], "테스트파일~.jpg"),
          //   })
          // }
          >
            /resource/insert
          </Button>
          <Button
            onClick={
              () =>
                siteResources.length === 0
                  ? alert("리스트를 호출해주세요")
                  : null
              // : deleteSiteResource(
              //     siteResources[siteResources.length - 1].resource_id
              //   )
            }
          >
            /resource/delete
          </Button>
        </Box>
      </Box>
      <Box direction="column">
        <Typography size={20} isBold>
          /images
        </Typography>
        <Box classes="apis">
          <Button onClick={() => getImages({ size: 20 })}>/</Button>
          <Button
            onClick={() =>
              uploadImage({
                file: new File([], "테스트파일~.png"),
                request: { imageName: "ss" },
              })
            }
          >
            /upload
          </Button>
          <Button
            onClick={
              () =>
                images.length === 0 ? alert("리스트를 호출해주세요") : null

              // updateImage({
              //     image_id: images[0].imageId,
              //     image_name: "st",
              //   })
            }
          >
            /update
          </Button>
          <Button
            onClick={() =>
              images.length === 0
                ? alert("리스트를 호출해주세요")
                : updateFileImage({
                    id: images[0].imageId,
                    file: new File([], "수정파일~.png"),
                    fileName: "st",
                  })
            }
          >
            /file/update
          </Button>
          <Button
            onClick={() =>
              images.length === 0
                ? alert("리스트를 호출해주세요")
                : deleteImage({
                    request: {
                      imageIds: [sites[0].siteId],
                    },
                  })
            }
          >
            /delete
          </Button>
        </Box>
      </Box>
      <Box direction="column">
        <Typography size={20} isBold>
          /attach
        </Typography>
        <Box classes="apis">
          <Button onClick={() => getAttaches({ size: 20 })}>/</Button>
          <Button
          // onClick={() =>
          //   Attach({
          //     file: new File([], "테스트파일~.hwp"),
          //     fileName: "테스트",
          //   })
          // }
          >
            /insert
          </Button>
          <Button
            onClick={
              () =>
                attaches.length === 0 ? alert("리스트를 호출해주세요") : null

              // updateAttach({
              //     id: attaches[0].documentId,
              //     editFileName: "수정수정" + (attaches[0].documentId + 1),
              //   })
            }
          >
            /update
          </Button>
          <Button
            onClick={() =>
              attaches.length === 0
                ? alert("리스트를 호출해주세요")
                : updateAttachFile({
                    fileName: "수정수정" + (attaches[0].documentId + 1),
                    file: new File([], "테스트파일~.hwp"),
                    filePath: attaches[0].documentPath,
                    id: attaches[0].documentId,
                  })
            }
          >
            /file/update
          </Button>
          <Button
            onClick={() =>
              attaches.length === 0
                ? alert("리스트를 호출해주세요")
                : downloadAttachFile(2)
            }
          >
            /download/id
          </Button>
        </Box>
      </Box>

      <Box direction="column">
        <Typography size={20} isBold>
          /dashboard
        </Typography>
        <Box classes="apis">
          <Button onClick={() => getReportHistory()}>/reportHistory</Button>
          <Button
            onClick={() =>
              getRecentClickHistoryList({ configId: DUMMY_CONFIG_ID, cnt: 5 })
            }
          >
            /recentClickHistory/list
          </Button>
          <Button onClick={() => getProgressList(DUMMY_CONFIG_ID)}>
            /progress/list
          </Button>
          <Button onClick={() => getDashboardReport(DUMMY_CONFIG_ID)}>
            /report
          </Button>
          <Button onClick={() => getSendHistoryList(DUMMY_CONFIG_ID)}>
            /sendHistory
          </Button>
          <Button onClick={() => getPhishTypes(DUMMY_CONFIG_ID)}>
            /phishTypes
          </Button>
        </Box>
      </Box>
      <Box direction="column">
        <Typography size={20} isBold>
          /managers
        </Typography>
        <Box classes="apis">
          <Button onClick={() => getManagerList()}>/</Button>
          <Button
            onClick={() =>
              managers.length === 0
                ? alert("리스트를 호출해주세요")
                : addNewManager({
                    managerCode: "",
                    managerName: "바경우" + managers.length,
                    managerPwd: "Asdf1234$$$$",
                    managerEmail: "parkyw1206@gmail.com",
                    customerCode: "d42585b2f4ce466a97b62f28f4236d77",
                    menuAccess: "[2, 5, 3, 4]",
                    managerType: 2,
                  })
            }
          >
            /add
          </Button>
          <Button
            onClick={() =>
              managers.length === 0
                ? alert("리스트를 호출해주세요")
                : updateManagerInfo({
                    managerCode: "",
                    managerName: "바경우" + (managers.length + 12),
                    managerPwd: "Asdf1234$$$$",
                    managerEmail: "parkyw1206@gmail.com",
                    customerCode: "d42585b2f4ce466a97b62f28f4236d77",
                    menuAccess: "[2, 5, 3, 4]",
                    managerType: 2,
                  })
            }
          >
            /update
          </Button>
          <Button
            onClick={
              () =>
                managers.length === 0 ? alert("리스트를 호출해주세요") : null
              // deleteManager([managers[0].customerCode])
            }
          >
            /delete
          </Button>
          <Button
            onClick={() =>
              getCommonMangerList("d42585b2f4ce466a97b62f28f4236d77")
            }
          >
            /common
          </Button>
        </Box>
      </Box>
      <Box direction="column">
        <Typography size={20} isBold>
          /statistics
        </Typography>
        <Box classes="apis">
          <Button onClick={() => getStaticsList()}>/list</Button>
          <Button
            onClick={() =>
              getStaticsTraineGraphListConfig([
                29, 26, 23, 21, 20, 19, 16, 15, 14, 12, 11, 10, 9, 6, 3, 2,
              ])
            }
          >
            /getTraineGraphList/configs
          </Button>
          <Button
            onClick={() =>
              getStaticsTraineGraphListType({
                startDate: 1709251200,
                endDate: 1709251200,
                realTrainingFlag: false,
              })
            }
          >
            /getTraineGraphList/type
          </Button>
          <Button
            onClick={() =>
              getStaticsTraineGraphList({
                startDate: "2023-03-25+00:00:00",
                endDate: "2023-03-25+00:00:00",
              })
            }
          >
            /getTraineGraphList
          </Button>
          <Button
            onClick={() =>
              getPhishingGraph({
                configId: 16,
                clickType: 1,
              })
            }
          >
            /phishing
          </Button>
        </Box>
      </Box>
      <Box direction="column">
        <Typography size={20} isBold>
          /config
        </Typography>
        <Box classes="apis">
          <Button onClick={() => getConfigInfo(DUMMY_CONFIG_ID)}>/info</Button>
          <Button onClick={() => getConfigList()}>/list</Button>
        </Box>
      </Box>
      <Box direction="column">
        <ToolTip2 message={"detail 만 /program"}>
          <Typography size={20} isBold color={"red"}>
            /programs
          </Typography>
        </ToolTip2>
        <Box classes="apis">
          <Button onClick={() => getProgramList({ size: 20 })}>/list</Button>
          <Button
            onClick={() =>
              configList.length === 0
                ? alert("리스트를 호출해주세요")
                : getProgramDetail(configList[0].configId)
            }
          >
            /detail
          </Button>
          <Button
          // onClick={() =>
          //   addNewProgram({
          //     configName: "박영우테스트",
          //     startDate: "2024-03-25 00:00:00",
          //     endDate: "2024-04-25 23:59:00",
          //     count: 4,
          //     templates: [9, 8, 6, 1],
          //     isAllUsers: false,
          //     isAllTemplates: true,
          //     entryTime: "2024-03-25 09:40:36",
          //     startTime: null,
          //     endTime: null,
          //     realTrainingFlag: false,
          //   })
          // }
          >
            /add
          </Button>
          <Button
            onClick={() =>
              configList.length === 0
                ? alert("리스트를 호출해주세요")
                : deleteProgram(configList[0].configId)
            }
          >
            /delete
          </Button>
          <Button
            onClick={
              () =>
                configList.length === 0 ? alert("리스트를 호출해주세요") : null
              // updateProgram({
              //     configId: configList[0].configId,
              //     sendStartTime: "00:00",
              //     sendEndTime: "23:59",
              //   })
            }
          >
            /update
          </Button>
          <Button
            onClick={() =>
              configList.length === 0
                ? alert("리스트를 호출해주세요")
                : activateProgram({
                    configId: configList[0].configId,
                    isActivated: true,
                  })
            }
          >
            /update/active
          </Button>
        </Box>
      </Box>
      <Box direction="column">
        <Typography size={20} isBold>
          /sendHistory
        </Typography>
        <Box classes="apis">
          {/* <Button onClick={() => getSendHistoryById(DUMMY_CONFIG_ID)}>
            /$id
          </Button>
          <Button
            onClick={() =>
              sendHistoryList.length === 0
                ? alert("리스트를 호출해주세요")
                : reportSendHistory({
                    sid: sendHistoryList[0].sendHistoryId,
                    isReported: false,
                  })
            }
          >
            /report
          </Button> */}
          <Button onClick={() => checkMailExist(DUMMY_CONFIG_ID)}>
            /mail/exists
          </Button>
          <Button onClick={() => sendResultMail(DUMMY_CONFIG_ID)}>
            /send/result
          </Button>
        </Box>
      </Box>
      <Box direction="column">
        <Typography size={20} isBold>
          /getMailTemplateList
        </Typography>
        <Box classes="apis">
          <Button
            onClick={() => {
              getMailTemplateList({ size: 20 });
              getImages({ size: 20 });
            }}
          >
            /
          </Button>
          <Button onClick={() => getMailTemplateInfo()}>/getInfo</Button>
          <ToolTip2
            message={
              "/getMailTemplateList 로 시작하지 않으나, getMailTemplateList/insert 와 동일하다 생각해 해당 위치로 표시"
            }
          >
            <Button
              onClick={() =>
                insertMailTemplate({
                  file: new File([], "테스트파일~.txt"),
                  request: {
                    templateName: "abc",
                    sendEmail: "parkyw1206@fasoo.com",
                    sendName: "asdfasdfasdf",
                    subject: "ㄴ",
                    siteId: 3,
                    imageId: 1,
                    documentId: 1,
                    categorys: null,
                  },
                })
              }
              color={"lightWarning"}
            >
              /insertMailTemplate
            </Button>
          </ToolTip2>
          <ToolTip2
            message={
              "/getMailTemplateList 로 시작하지 않으나, getMailTemplateList/insert 와 동일하다 생각해 해당 위치로 표시"
            }
          >
            <Button
              // onClick={() =>
              // updateMailTemplate({
              //   template: {
              //     templateName: "abc",
              //     sendEmail: "parkyw1206@fasoo.com",
              //     // templateFile: new File([], "테스트파일~.txt"),
              //     sendName: "asdfasdfasdf",
              //     subject: "ㄴ",
              //     siteId: 3,
              //     imageId: 1,
              //     documentId: 1,
              //     categorys: null,
              //     orgTemplateName: "abc",
              //   },
              //   id: 9,
              // })
              // }
              color={"lightWarning"}
            >
              /updateTemplate
            </Button>
          </ToolTip2>
          <ToolTip2
            message={
              "/getMailTemplateList 로 시작하지 않으나, getMailTemplateList/usedTemplate 와 동일하다 생각해 해당 위치로 표시"
            }
          >
            <Button onClick={() => getUsedTemplate()} color={"lightWarning"}>
              /getUsedTemplate
            </Button>
          </ToolTip2>
          <ToolTip2
            message={
              "/getMailTemplateList 로 시작하지 않으나, getMailTemplateList/getMailTemplateData 와 동일하다 생각해 해당 위치로 표시"
            }
          >
            <Button
              onClick={() =>
                mailTemplateList.length === 0
                  ? alert("리스트를 호출해주세요")
                  : getMailTemplateData(1)
              }
              color={"lightWarning"}
            >
              /getMailTemplateData
            </Button>
          </ToolTip2>
          <ToolTip2
            message={"/getMailTemplateList 로 시작하지 않으나 성격이 미슷"}
          >
            <Button
              onClick={() =>
                parseEMLFile({ file: new File([], "새 파일~.eml") })
              }
              color={"lightWarning"}
            >
              /eml/parser
            </Button>
          </ToolTip2>
          <ToolTip2
            message={
              "/getMailTemplateList 로 시작하지 않으나, getMailTemplateList/delete 와 동일하다 생각해 해당 위치로 표시"
            }
          >
            <Button
              onClick={
                () =>
                  mailTemplateList.length === 0
                    ? alert("리스트를 호출해주세요")
                    : null
                // deleteMailTemplate(mailTemplateList.length - 1)
              }
              color={"lightWarning"}
            >
              /deleteMailTemplate
            </Button>
          </ToolTip2>
        </Box>
      </Box>
      <Box direction="column">
        <Typography size={20} isBold>
          /traineStatistics
        </Typography>
        <Box classes="apis">
          <Button
            onClick={() =>
              getTraineGraphList({
                startDate: "2023-03-22+00:00:00",
                endDate: "2024-03-22+00:00:00",
              })
            }
          >
            /getTraineGraphList
          </Button>
          <Button
            onClick={() =>
              getInpercent({
                startDate: "2023-03-22+00:00:00",
                endDate: "2024-03-22+00:00:00",
              })
            }
          >
            /inpercent
          </Button>
          <Button
            onClick={() =>
              getTraineGraphListType({
                startDate: 1709251200,
                endDate: 1709251200,
                realTrainingFlag: true,
              })
            }
          >
            /getTraineGraphList/type
          </Button>
          <Button
            onClick={() =>
              getInpercentType({
                startDate: 1709251200,
                endDate: 1709251200,
                realTrainingFlag: true,
              })
            }
          >
            /inpercent/type
          </Button>
          <Button
            onClick={() =>
              getInpercentConfig([
                29, 26, 25, 23, 22, 21, 20, 19, 16, 15, 14, 12, 11, 10, 9, 6, 3,
                2,
              ])
            }
          >
            /getTraineGraphList/config
          </Button>
          <Button
            onClick={() =>
              getTraineGraphListConfig([
                29, 26, 25, 23, 22, 21, 20, 19, 16, 15, 14, 12, 11, 10, 9, 6, 3,
                2,
              ])
            }
          >
            /inpercent/config
          </Button>
        </Box>
      </Box>
      <Box direction="column">
        <Typography size={20} isBold>
          /account
        </Typography>
        <Box classes="apis">
          <Button onClick={() => sessionLogOut()}>/logout</Button>
        </Box>
      </Box>
      <Box direction="column">
        <Typography size={20} isBold>
          /customers
        </Typography>
        <Box classes="apis">
          <Button
            onClick={() => selectCustomer("d42585b2f4ce466a97b62f28f4236d77")}
          >
            /select
          </Button>
          <Button
            onClick={() => getCustomerName("d42585b2f4ce466a97b62f28f4236d77")}
          >
            /name
          </Button>
          <Button onClick={() => getAccessCompanyList()}>/origin</Button>
          <Button onClick={() => getCustomersList()}>/</Button>
          <Button
            onClick={() =>
              updateCustomerInfo({
                companyName: "랩소디개발팀B",
                customerCode: "9760866a088249d9ac66a7f22a44fe66",
              })
            }
          >
            /update
          </Button>
          <Button
            onClick={() =>
              checkCustomerTraining("9760866a088249d9ac66a7f22a44fe66")
            }
          >
            /training
          </Button>
          <Button onClick={() => getCustomerCategory()}>/categories</Button>
          <Button onClick={() => getCustomerSetting()}>/settings</Button>
        </Box>
      </Box>
      <Box direction="column">
        <Typography size={20} isBold>
          /category
        </Typography>
        <Box classes="apis">
          <ToolTip2
            message={
              "/category 로 시작하지 않으나, category/list 와 동일하다 생각해 해당 위치로 표시"
            }
          >
            <Button onClick={() => getCategories()} color={"lightWarning"}>
              /getCategories
            </Button>
          </ToolTip2>
          <Button
            onClick={() =>
              categoryList.length === 0
                ? alert("리스트 호출 해주세요")
                : addCategory("안녕" + categoryList.length + 1)
            }
          >
            /add
          </Button>
          <Button
            onClick={
              () =>
                categoryList.length === 0 ? alert("리스트 호출 해주세요") : null
              // updateCategory({
              //     category_code:
              //       categoryList[categoryList.length - 1].category_code,
              //     category_name:
              //       categoryList[categoryList.length - 1].category_name + "~",
              //   })
            }
          >
            /update
          </Button>
          <Button
            onClick={
              () =>
                categoryList.length === 0 ? alert("리스트 호출 해주세요") : null
              // deleteCategory(
              //     categoryList[categoryList.length - 1].category_code
              //   )
            }
          >
            /delete
          </Button>
        </Box>
      </Box>
      <Box classes="thumbnails">
        {/* {mailTemplateList?.map((item) => (
          <HTMLThumbnail
            mail={item}
            image={images.filter((img) => img.imageId === item.image_id)[0]}
          />
        ))} */}
      </Box>
    </Box>
  );
};
export default DummyAPIPage;
